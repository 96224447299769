<template>
  <div class="login-container">
    <div class="login-head">
      <login-form-head></login-form-head>
    </div>
    <div class="login-main">
      <login-form :pathName="pathName"></login-form>
    </div>
    <floot-nav :navColor="$route.meta.navColor"></floot-nav>
  </div>
</template>

<script>
import { forwardBID } from '@/api/forward.js'
import LoginForm from '@/components/login-form/login-form'
import flootNav from '@/components/flootNav/flootNav.vue'
import LoginFormHead from '@/components/login-form-head/login-form-head'
import { ref } from 'vue'

export default {
  name: 'Login',
  components: { LoginFormHead, LoginForm, flootNav },
  data () {
    return {
      input: '1',
      input2: '2'
    }
  },
  created () {
  },
  methods: {
    handleSubmit: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/bid/user/login',
        dateBackgroundRequestType: 'post',
        data: {
          loginName: vm.input,
          password: vm.input2
        }
      }
      forwardBID(data).then(result => {
        if (result.code === 0) {
          const idToken = result.data.idToken
          console.log(idToken)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  setup () {
    const pathName = ref('/index')
    return {
      pathName
    }
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
}

.login-main {
  width: 100%;
  height: 100%;
  margin: 150px 0;
  display: flex;
  justify-content: center;
}

</style>
